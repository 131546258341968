import React, { Component } from 'react';

class Footer extends Component {

  render() {
    return (

      // rgb(171, 191, 192)'
      <section className="section-footer card-footer" style={{"backgroundColor": 'rgb(141, 140, 143)'}}>
        {/* <footer className="garbage"> */}
          <div className="row">
            <div className="col-md-3 col-sm-8">
              <a href="https://github.com/zeroIndex0" target="_blank" rel="noopener noreferrer">
                <button className="btn btn-link">
                  <img src={require("../images/github.png")} alt="github button icon" />
                  Github
              </button>
              </a>
            </div>
            <div className="col-md-3 col-sm-8">
              <a href="https://www.linkedin.com/in/ryan-k-2a6a3715a/" target="_blank" rel="noopener noreferrer">
                <button className="btn btn-link">
                  <img src={require("../images/linkedin.png")} alt="linkedin button icon" />
                  Linkedin
              </button>
              </a>
            </div>
            <div className="col-md-3 col-sm-8">
              <a href="https://www.codewars.com/users/zeroIndex" target="_blank" rel="noopener noreferrer">
                <button className="btn btn-link">
                  <img src={require("../images/cw.PNG")} alt="codewars button icon" />
                  CodeWars
              </button>
              </a>
            </div>
            <div className="col-md-3 col-sm-8">
              <a href="mailto:ryank.businessmail@gmail.com">
                <button className="btn btn-link">
                  <img src={require("../images/envelope.png")} alt="email button icon" />
                  Email
              </button>
              </a>
            </div>
          </div>
        {/* </footer> */}
        <h6 id="copyright">The Kirbs &copy; 2025</h6>
      </section>
    );
  }
}

export default Footer;
